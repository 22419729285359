import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  label?: string
  title?: string
  variant?: Variant
}

export const Intro = memo(function Intro({
  description,
  image,
  label,
  title,
  variant = 'default',
}: Props) {
  return (
    <Container dial={4} row tag="section" wrap variant={variant}>
      {variant !== 'simple' ? <LeftMask variant={variant} /> : null}
      <RightMask dial={5} variant={variant}>
        <IconLogo />
      </RightMask>

      {variant === 'advanced' ? (
        <Background>
          {image ? (
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          ) : null}
        </Background>
      ) : null}

      <Wrapper variant={variant}>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title className="intro-title">
              {title}
              <Line />
            </Title>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  margin-bottom: 12.5rem;
  padding: 10.9375rem 1.875rem 12.1875rem;
  position: relative;

  ${({ variant }) => {
    switch (variant) {
      case 'advanced':
        return css`
          padding-right: 10.556vw;
          padding-left: 10.556vw;
        `
      case 'simple':
        return css`
          padding-bottom: 0;
        `
    }
  }}

  @media (max-width: 1199px) {
    margin-bottom: 7.5rem;
    padding: 7.5rem 1.875rem 5.625rem;
  }

  @media (max-width: 1023px) {
    padding-top: 5.625rem;
    padding-bottom: 0;
    margin-bottom: 5.625rem;
  }

  @media (max-width: 767px) {
    padding: 3.75rem 1.25rem 0;
  }
`

const LeftMask = styled(FlexBox)<ContainerProps>`
  width: calc(50% - 12.5rem);
  height: 41.7vh;
  max-height: 22.3125rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;

  ${({ variant }) => {
    switch (variant) {
      case 'advanced':
        return css`
          height: 50%;
          max-height: none;
          background: ${theme.colors.variants.neutralDark2};
        `
    }
  }}

  @media (max-width: 1023px) {
    display: none;
  }
`

const RightMask = styled(FlexBox)<ContainerProps>`
  width: calc(50% - 12.5rem);
  height: 46.6vh;
  max-height: 25rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;

  svg {
    width: auto;
    height: 5.625rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight2};
  }

  ${({ variant }) => {
    switch (variant) {
      case 'advanced':
        return css`
          height: calc(50% - 3.125rem);
          max-height: none;
        `
    }
  }}

  @media (max-width: 1023px) {
    display: none;
  }
`

const Background = styled.div`
  width: calc(50% - 7.8vw);
  padding-bottom: 55.3%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 1.875rem;
    padding-bottom: 50%;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Wrapper = styled.div<ContainerProps>`
  max-width: 40.625rem;
  margin: auto;

  ${({ variant }) => {
    switch (variant) {
      case 'advanced':
        return css`
          width: 50%;
          max-width: none;
          margin-left: 7.8vw;

          .intro-title {
            max-width: 26.875rem;
          }

          @media (max-width: 1199px) {
            margin-left: 3.75rem;
          }
        `
    }
  }}

  @media (max-width: 1023px) {
    width: 100%;
    max-width: none;
    margin: 0;

    .intro-title {
      max-width: none;
    }
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;

  > div {
    margin-top: 1.25rem;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin-top: 2.5rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 1.75rem;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'advanced' | 'simple'
